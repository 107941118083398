import { SEO } from "gatsby-theme-core";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@lendex/helper/methods";
import { Layout, HeaderOne, FooterOne } from "gatsby-theme-layout";
import { ContactUs01, PageHeader01 } from "gatsby-theme-container";
import { GoogleMap } from "gatsby-theme-ui";

const ContactUsPage = ({ data }) => {
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO pageName="Contact Us" />
            <HeaderOne data={{ ...data.header, ...data.navigation }} />
            <PageHeader01 data={content?.["page-header-section"]} />
            <GoogleMap data={{ map: data?.contact?.map }} />
            <ContactUs01
                data={{
                    contact_info: data.contact?.contact_info,
                    socials: data?.contact?.socials,
                    section_title: data?.contact?.section_title,
                }}
            />
            <FooterOne data={{ ...data.footer, ...data?.contact }} />
        </Layout>
    );
};
export const query = graphql`
    query ContactPageQuery {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        page(title: { eq: "Contact-page" }) {
            content {
                ...PageContent
            }
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact02
        }
    }
`;

ContactUsPage.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({
            contact_info: PropTypes.shape({}),
            map: PropTypes.shape({}),
            socials: PropTypes.arrayOf(PropTypes.shape({})),
            section_title: PropTypes.shape({}),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export default ContactUsPage;
